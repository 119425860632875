import {Box, Flex, Heading} from "@chakra-ui/react";
import React from "react";
import {PortableText} from "@portabletext/react";
import ContentSectionCTA from "../components/modules/contentSectionCTA";

const ContentSection = ({data, components, buttonData}) => {
	const contentSectionComponents = {
		...components,
		block: {
			h2: ({children}) => <Box marginTop={{base: "30px", lg: "60px"}}><Heading as="h2" fontSize={{base: "32px", lg: "36px"}}
																											 fontFamily="Merriweather"
																											 fontWeight="400">{children}</Heading></Box>,
			h3: ({children}) => <Box marginTop={{base: "30px", lg: "60px"}}><Heading as="h3" fontSize={{base: "27px", lg: "30px"}}
																											 fontFamily="Merriweather"
																											 fontWeight="400">{children}</Heading></Box>,
			normal: ({children}) => <Box marginTop={{base: "15px", lg: "24px"}} fontSize={{base: "16px", lg: "18px"}} fontFamily="sourceSansPro"
												  color="#3d3d3d">{children}</Box>
		},
	}
	
	const aboveCTA = data?.sanityPillarPage?.content?._rawContentSection?.slice(0, 2);
	const belowCTA = data?.sanityPillarPage?.content?._rawContentSection?.slice(2);
	
	return <Flex className='pillar-page-section content-section' backgroundColor='#F4F4F4'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '90px'}} marginBottom={{base: '75px', lg: '95px'}}
				align="flex-start" maxWidth="850px">
			<Box fontSize={{base: "16px", lg: '18px'}}>
				<PortableText value={aboveCTA || ""} components={contentSectionComponents}></PortableText>
			</Box>
			<ContentSectionCTA data={buttonData}></ContentSectionCTA>
			<Box fontSize={{base: "16px", lg: '18px'}}>
				<PortableText value={belowCTA || ""} components={contentSectionComponents}></PortableText>
			</Box>
		</Flex>
	</Flex>;
}

export default ContentSection;