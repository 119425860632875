import {Box, Text} from "@chakra-ui/react";
import LazyHydrate from "react-lazy-hydration";
import CtaButton from "../Sections/ctaButton";
import React from "react";

const ContentSectionCTA = ({data, components}) => {
	return <>
		<Box marginTop={{base: "30px", lg: "60px"}} borderBottom={'1px solid #d8d8d8'} width='100%' alignSelf="center"/>
		<Text as='h4'
				fontSize={{base: '20px', lg: '24px'}}
				marginTop={{base: "30px", lg: "30px"}}
				lineHeight="24px"
				alignSelf={{base: "center", sm: "flex-start"}}
				textAlign={{base: "center", sm: "left"}}>Get the grades, get the score, get the school</Text>
		<Box fontFamily='sourceSansPro'
			  marginTop={{base: "15px", lg: "24px"}}
			  marginBottom={{base: "20px", md: 0}}
			  fontSize={{base: '16px', lg: '18px'}}
			  alignSelf={{base: "center", sm: "flex-start"}}
			  textAlign={{base: "center", sm: "left"}}>
			<Text as='span' textAlign={{base: "center", sm: "left"}}>
				Our tutoring elevates everything, including grades, test scores, and the confidence students have both in school and standardized testing.
			</Text>
		</Box>
		<Box alignSelf={{base: "center", sm: "flex-start"}}>
			<LazyHydrate whenVisible><CtaButton data={data} locatorString="content_cta_button"/></LazyHydrate>
		</Box>
		<Box marginTop={{base: "15px", lg: "10px"}} marginBottom={{base: "15px", lg: "30px"}} borderBottom={'1px solid #d8d8d8'} width='100%' alignSelf="center"/>
	</>
};

export default ContentSectionCTA;