//~ DEPENDENCIES
import React from 'react'
import {graphql, Link} from 'gatsby'
import LazyHydrate from "react-lazy-hydration"

//~ COMPONENTS
import Layout from '../components/layout'
import Seo from '../components/Seo/index'

//~ MEDIA

//~ MISCELLANEOUS
import {Box, Flex, Text} from "@chakra-ui/react";
import LeadForm from "../components/Interactive/leadForm";
import ThreeColumn from '../components/Collections/threeColumn'
import TutorsSlider from "../components/Sections/Sliders/tutorsSlider";
import TestimonialsSlider from "../components/Sections/Sliders/testimonialsSlider";
import CtaButton from "../components/Sections/ctaButton";
import TutorSchoolLogos from "../components/Sections/tutorSchoolLogos";
import Template from "../components/Interactive/video";
import UpdatedStatsShowcase from "../components/Sections/updatedStatsShowcase";
import ContentSection from "./contentSection";
import FAQSection from "./faqSection";
import Images from "../components/Interactive/image";
import Slider from "../components/Interactive/slider";
import {startOptimizedAppearAnimation} from "framer-motion";

const HeaderSection = ({data}) => {
	const imgObj = data?.sanityPillarPage?.header?.bannerImage?.asset?.gatsbyImageData;
	const imgSrc = imgObj?.images?.fallback?.src;
	let tradeMarkText;
	if (data?.sanityPillarPage?.header?.title) {
		tradeMarkText = data?.sanityPillarPage?.header?.title.split('®');
	}
	let headerButtonData = {
		text: "Get matched with a tutor",
		textLink: "https://webforms.pipedrive.com/f/ckowS7mK7ncSoHbcIVJQS1L9PTqly8RfxEUlReVmk6QdfrgND0dfVSnyyAh08jyBtV",
		_rawButtonColor: {color: "white", value: "#FFFFFF"},
		_rawButtonTextColor: {color: "dark", value: "#000000"}
	};
	
	return <Flex align='center'
					 justify='center'
					 bgImage={`url(${imgSrc})`}
					 bgRepeat='repeat'
					 bgSize='auto'
					 width="100%"
					 flexDirection="column">
		
		<Flex marginTop={{base: "45px", lg: "60px"}} width="100%" align="center" justify="center">
			 <Text as='h1'
					 display='block'
					 fontSize={{base:'38px', lg: '56px'}}
					 lineHeight={{base: "40px", md: "60px"}}
					 fontFamily='merriweather'
					 textAlign='center'
					 color='white'
			 >
				 {tradeMarkText[0]}
			 </Text>
			 { tradeMarkText?.length > 1 &&
			  <>
				  <Text
					fontSize={{base: '12px', lg: '12px'}}
					lineHeight={{base: '3.3', lg: '4.7'}}
					fontFamily='merriweather'
					textAlign='center'
					color='white'
					position="relative"
					top={{base: "-7px", lg: "-14px"}}
				  >
					  ®&nbsp;&nbsp;&nbsp;
				  </Text>
					 <Text as='h1'
							 display='block'
							 fontSize={{base: '38px', lg: '56px'}}
							 lineHeight={{base: "40px", md: "60px"}}
							 fontFamily='merriweather'
							 textAlign='center'
							 color='white'
					 >
						 {tradeMarkText[1]}
					 </Text>
				 </>
			 }
		 </Flex>
		<Flex>
			<Text fontSize={{base: '24px', lg: '30px'}}
					fontFamily='sourceSansPro'
					textAlign='center'
					color='white' marginTop="20px">
				Answer a few questions to connect with your perfect tutor
			</Text>
		</Flex>
		<Box marginBottom="36px" marginTop={{base: "30px", lg: "10px"}}>
			<LazyHydrate whenVisible><CtaButton data={headerButtonData} locatorString="tutors_cta_button_white"/></LazyHydrate>
		</Box>
	</Flex>;
}

const WhyEnhancedPrepSection = ({data}) => {
	return <Flex className='pillar-page-section'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '125px'}} marginBottom={{base: '60px', lg:'100px'}}>
			<Text as='h3' fontSize={{base: '26px', lg: '36px'}}>Why Enhanced Prep?</Text>
			<Box marginTop='30px' width={{base: '100%', lg: '976px'}} marginBottom={{base: '15px', lg: '0px'}} textAlign='center'>
				<Text as='span' fontSize={{base: '18px', lg: '21px'}} fontFamily='sourceSansPro'>
					Many tutoring companies claim that they can help students increase their test scores, but very few can support those claims and almost none of them focus on a student's ultimate goal - getting into their dream school.
					Nowadays, it takes more than a single strong test score to get into college. Students have got to have the grades and the test scores and then some.
					At Enhanced Prep, we provide a truly customizable approach to tutoring, focusing on each student's specific needs. We match students with the right tutor who can help them study for a biology test one day and prep for their upcoming college entrance exam the next.
				</Text>
			</Box>
			<Box width='100%'>
				<UpdatedStatsShowcase />
			</Box>
			<LazyHydrate whenVisible><CtaButton data={defaultButtonData} locatorString="why_enhanced_prep_cta_button"/></LazyHydrate>
		</Flex>
	</Flex>;
}

const TutorSection = ({data}) => {
	if(data?.sanityPillarPage?.header?.title === 'College Advising'){
		return <></>;
	}
	return <Flex className='pillar-page-section' backgroundColor='#F4F4F4'>
			<Flex className='pillar-page-section-contents' marginTop={{base: '88px', lg: '140px'}} marginBottom={{base: '75px', lg: '95px'}}>
				<Text as='h3' fontSize={{base: '26px', lg: '36px'}} marginBottom={{base: "40px", lg: "50px"}} textAlign='center'>Tutors with Superior Credentials & Expertise</Text>
				<LazyHydrate whenVisible><TutorsSlider data={data.sanityPillarPage?.tutors?.tutorsList} /></LazyHydrate>
				<TutorSchoolLogos />
			</Flex>
		</Flex>;
}

const ThreePillarsSection = ({data}) => {
	return <Flex className='pillar-page-section'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '90px'}} marginBottom={{base: '60px', lg: '130px'}}>
			<Box marginBottom='30px'>
				<Text as='h4' fontFamily='sourceSansPro' fontSize={{base: '24px', lg: '30px'}} textAlign="center">How Enhanced Prep Maximizes Scores</Text>
			</Box>
			<Text as='span' fontFamily='sourceSansPro' fontSize={{base: '18px', lg: '21px'}} textAlign='center' marginBottom='20px'>Our learning model, the Three Pillars, helps students maximize their standardized test scores.</Text>
			<LazyHydrate whenVisible><ThreeColumn stretch={true} data={data?.sanityPillarPage?.threePillars?.threePillars?.pillars}></ThreeColumn></LazyHydrate>
		</Flex>
	</Flex>;
}

const TestimonialSection = ({data}) => {
	return <Flex className='pillar-page-section' backgroundColor='#E9F9FB'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '67px', lg: '86px'}} marginBottom={{base: '44px', lg: '86px'}}>
			<Box marginBottom={{base: '10px', lg: '40px'}}>
				<Text as='h3' fontSize={{base: '26px', lg: '36px'}}>Testimonials</Text>
			</Box>
			<LazyHydrate whenVisible><Template data={{videoUrl: "https://fast.wistia.com/embed/medias/tfc6cbxk91.jsonp", desktopWidthPixels: "600"}} locatorString="testimonial_video"/></LazyHydrate>
			<LazyHydrate whenVisible><TestimonialsSlider data={data?.sanityPillarPage?.testimonials?.testimonials} /></LazyHydrate>
		</Flex>
	</Flex>;
}

const PromoModuleSection = ({data}) => {
	const promoPageList = data?.sanityPillarPage?.promoModule?.promoPageList;
	const subjectName = data?.sanityPillarPage?.promoModule?.subjectName;
	
	const renderPromoPage = (promoPage) => {
		return <div key={promoPage.id} className="pillar-page-promo-module-slide">
			<Images image={promoPage.header?.promoImage?.asset?.gatsbyImageData} alt="" width='100%' height="75px" objectFit="cover" />
			<div className="pillar-page-promo-module-slide__link">
				<a href={"/resources/" + promoPage.header?.slug?.current + (promoPage.header?.slug?.current?.endsWith("/") ? "" : "/")}>{promoPage.header?.title}</a>
			</div>
		</div>;
	}
	
	return <Flex className='pillar-page-section'>
		<Flex className='pillar-page-section-contents' marginTop={{base: '60px', lg: '90px'}} marginBottom={{base: '60px', lg: '130px'}}>
			<h3 className="pillar-page-promo-module-header">{"More " + subjectName + " Resources"}</h3>
			<h4 className="pillar-page-promo-module-subheader">Get advice from our tutors on how to succeed before, during,
				and after your test.</h4>
			<div className="pillar-page-promo-module-list">
				<div className="pillar-page-promo-module-category">
					{promoPageList.length < 5 &&
					 <div className="pillar-page-promo-module-wrapper">
						 {
							 promoPageList.map((promoPage, index) => renderPromoPage(promoPage))
						 }
					 </div>
					}
					{promoPageList.length > 4 &&
					 <Slider
					  navigation
					  navigationVisibleMinWidth="1024px"
					  slidesPerViewBase={1}
					  slidesPerViewSm={2}
					  slidesPerViewMd={3}
					  slidesPerViewLg={4}
					  locatorString="pillar_page_promo_module_slider"
					 >
						 {
							 promoPageList.map((promoPage, index) => renderPromoPage(promoPage))
						 }
					 </Slider>
					}
				</div>
			</div>
		</Flex>
	</Flex>;
}

const defaultButtonData = {
	text: "Get matched with a tutor",
	textLink: "https://webforms.pipedrive.com/f/ckowS7mK7ncSoHbcIVJQS1L9PTqly8RfxEUlReVmk6QdfrgND0dfVSnyyAh08jyBtV",
	_rawButtonColor: { color: "primary", value: "#00A800"},
	_rawButtonTextColor: { color: "white", value: "#FFFFFF"}
};

const PillarPage = ({location, data, pageContext}) => {
	const {header, footer} = pageContext;
	const seo = {
		title: data?.sanityPillarPage?.header?.metaTitle,
		metaDescription: data?.sanityPillarPage?.header?.metaDescription
	};
	
	const components = {
		marks: {
			link: ({children, value}) => {
				const target = value?.link?.blank ? '_blank' : '_self'
				const rel = !value?.href?.startsWith('/') ? 'noreferrer noopener' : undefined
				return (
				 <>
					 <Link to={value?.href || '/#'} target={target} rel={rel} className="link">
						 {children}
					 </Link>
				 </>
				)
			}
		},
		list: {
			bullet: ({children}) => <ul style={{margin: "10px 0 10px 30px"}}>{children}</ul>
		},
		listItem: {
			bullet: ({children}) => <li style={{fontFamily: "SourceSansPro", color: "#3d3d3d", margin: "10px 0"}}>{children}</li>
		}
	};
	//? CONTEXT
	return (
	 <Layout header={header} footer={footer}>
		 <Seo data={seo} location={location} />
		 <Flex flexDirection='column' overflowX='hidden'>
			 <HeaderSection data={data} />
			 <WhyEnhancedPrepSection data={data}/>
			 <TutorSection data={data}/>
			 <ThreePillarsSection data={data} />
			 <TestimonialSection data={data}/>
			 {data?.sanityPillarPage?.promoModule?.showPromoModule && <PromoModuleSection data={data}></PromoModuleSection>}
			 {data?.sanityPillarPage?.content && <ContentSection data={data} components={components} buttonData={defaultButtonData}></ContentSection>}
			 {data?.sanityPillarPage?.frequentQuestions?.faqList && <FAQSection data={data} components={components}></FAQSection>}
		 </Flex>
	 </Layout>
	);
}

export default PillarPage

export const query = graphql`
  query PillarPageQuery ($id: String){
    sanityPillarPage (id: {eq: $id}){
     	header{
     		metaTitle
     		metaDescription
     		title
     		bannerImage{
				 asset {
					gatsbyImageData
				 }
     		}
     	}
     	tutors{
     		tutorsList{
				tutorName
				tutorExperience
				tutorImage{
					asset {
						gatsbyImageData
					}
				}
				_rawTutorDescription(resolveReferences: {maxDepth: 50})
				tutorEducation{
					degree
					institution
				}
				tutorSubjects
     		}
     	}
     	form {
     		formTitle
     		formType
     		isTestPrep
     	}
		threePillars{
     		threePillars{
     			...SanityPillarCards
     		}
     	}
     	testimonials{
     		testimonials{
     			...SanityReview
     		}
     	}
		promoModule {
     		showPromoModule
     		subjectName
     		promoPageList {
				header{
					slug {
						current
					}
					title
					category
					promoImage {
						asset {
							gatsbyImageData
						}
					}
				}
				id
     		}
     	}
     	content{
     		_rawContentSection(resolveReferences: {maxDepth: 50})
     	}
     	frequentQuestions{
     		faqList {
     			questionTitle
     			_rawQuestionAnswer
     		}
     	}
    }
  }
`
