import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";
import {PortableText} from "@portabletext/react";

const FAQSection = ({data, components}) => {
	return <Flex className='pillar-page-section'>
		<Flex className='pillar-page-section-contents pillar-page-section-contents__align-items-flex-start' marginTop={{base: '60px', lg: '90px'}} marginBottom={{base: '75px', lg: '95px'}} align="flex-start" maxWidth="850px">
			<Text as='h3' fontSize={{base: '26px', lg: '36px'}}>Frequently Asked Questions</Text>
			{data?.sanityPillarPage?.frequentQuestions?.faqList?.map(q => (
				<Box key={q.questionTitle} id={q.questionTitle.slice(0,-1).toLowerCase().replace(/ /g, "-")}>
					<Text as='h3' fontSize={{base: '26px', lg: '30px'}} marginTop={{base: "30px", lg: "60px"}}>{q.questionTitle}</Text>
					<Box marginTop={{base: "15px", lg: "24px"}} fontSize={{base: "16px", lg: '18px'}}>
						<PortableText value={q._rawQuestionAnswer || ""} components={components}></PortableText>
					</Box>
				</Box>
			))}
		</Flex>
	</Flex>;
}

export default FAQSection;